<template>
  <v-app>
    <v-app-bar app color="primary" flat>
      <v-avatar class="mr-2">
        <v-img
          cover
          position="2px 2px"
          class="white"
          :src="require('@/assets/logo.png')"
        ></v-img>
      </v-avatar>
      <v-toolbar-title
        class="white--text headline text-uppercase font-weight-bold"
      >
        <span class="font-weight-medium">la pierrothèque</span>
        <span class="font-weight-light hidden-xs-only"> asso</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-content>
      <router-view />
    </v-content>

    <v-footer color="primary" class="font-italic title white--text py-3">
      <v-spacer></v-spacer>
      © La Pierrothèque Asso - 2021
      <v-spacer></v-spacer>
    </v-footer>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline-2 orange white--text mb-4 px-3">
          Soutenez La Pierrothèque
          <v-spacer> </v-spacer>
          <v-btn icon color="white" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text mt-4 class="body-2" style="text-align: justify;">
          En cette période difficile l'une des manières de nous soutenir c'est
          d'adhérer à notre association et/ou de faire du click and collect.<br /><br />
          Merci à vous.<br />
          <p class="text-right font-weight-bold">La PierroLoutre</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            target="__blank"
            @click="dialog = false"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe9dAaYzF5Qq9NdEWc_L_cPDtbev22juQmLZ91TbF_MOKlLnQ/viewform?fbclid=IwAR2BhDT1ZwF0zqg-hEeKuFgViA1WGYlXdCzMOi_WizmUoWIpj29OHqF6-dQ"
          >
            Click & Collect
          </v-btn>
          <v-btn
            color="primary"
            text
            target="__blank"
            @click="dialog = false"
            href="https://www.helloasso.com/associations/la-pierrotheque-asso"
          >
            Adhérer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    dialog: true
  })
};
</script>
