<template>
  <v-container fluid pa-0>
    <v-banner single-line color="error" v-model="banner">
      <v-icon slot="icon" color="white" size="36">
        mdi-information-outline
      </v-icon>
      <p class="headline white--text pt-3">Fermeture suite au mesures gouvernementales</p>
      <template v-slot:actions="{ dismiss }">
        <v-btn icon color="white" @click="dismiss">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-banner>

    <v-container
      fluid
      px-0
      :style="{ 'background-image': imgs.background }"
      class="bgTop"
    >
      <v-layout
        text-center
        align-center
        justify-center
        wrap
        :style="{ height: bgHeadHeight }"
      >
        <v-flex xs12>
          <h1 :class="['font-weight-bold', 'white--text', titleFontSize]">
            La Pierrothèque
          </h1>
          <h3 :class="['mb-5', 'white--text', subFontSize]">
            Bar ludique à Corbeil-Essonnes
          </h3>
          <h2
            :class="['font-weight-medium', 'pt-4', 'white--text', headFontSize]"
          >
            Jouer, Boire, S'amuser !
          </h2>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid grid-list-xl>
      <v-layout row wrap justify-center>
        <v-flex xs12 md4>
          <v-card class="elevation-10">
            <v-img class="white--text" :height="imgHeight" :src="imgs.join">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title>Adhérer</v-card-title>
            <v-card-text :style="{ height: textHeight }" class="body-1"
              >La Pierrothèque est une association, afin de profiter de
              l'établissement, une adhésion de 15€ pour les adultes et 8€ pour
              les -18 est demandée. Elle vous permettra de venir jouer en
              illimité jusqu'au 31 août 2021</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large right color="primary" style="visibility: hidden;"
                >En savoir +</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 md4>
          <v-card class="elevation-10">
            <v-img
              class="white--text"
              :height="imgHeight"
              :src="imgs.boardGames"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title>Jouer</v-card-title>
            <v-card-text :style="{ height: textHeight }" class="body-1">
              Notre ludothèque, en constante évolution, compte d'ores et déjà
              plus de 300 jeux ! Trop de choix ? on vous aide à trouver le bon
              et en prime, on vous l'explique ! Vous pouvez également venir avec
              vos propres jeux pour les faire découvrir aux autres adhérents
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                target="_blank"
                href="https://www.myludo.fr/#!/profil/la-pierrotheque-asso-8008"
                large
                right
                color="primary"
              >
                <v-icon left>mdi-eye</v-icon>Nos jeux
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 md4>
          <v-card class="elevation-10">
            <v-img
              class="white--text"
              :height="imgHeight"
              loading
              :src="imgs.drink"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title primary-title>Festoyer</v-card-title>
            <v-card-text :style="{ height: textHeight }" class="body-1"
              >La gorge sèche ? Un petit creux ? Testez nos divers produits
              issus (pour la plupart) de producteurs locaux. Bière, diabolo et
              planches seront de précieux alliés pour vos sessions
              endiablées.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                target="_blank"
                href="/menu_v1.pdf"
                large
                right
                color="primary"
              >
                <v-icon left medium>mdi-beer</v-icon>La carte
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid primary>
      <v-layout pa-2 row justify-center align-center wrap>
        <v-flex xs12 sm10 md8 lg4>
          <v-card class="pa-4 elevation-14 text-center">
            <h1 class="mb-3">Horaires</h1>
            <v-card-text class="title">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    Jeudi : réservation
                    <v-icon color="primary">mdi-information</v-icon>
                  </span>
                </template>
                <span class="text-center">
                  De 18h00 à 21h00<br />
                  Minimum 5 personnes et une semaine à l'avance
                  <br />Contactez-nous par téléphone, email, facebook
                </span>
              </v-tooltip>
              <br />Vendredi : 18h00 - 21h00 <br />Samedi : 15h - 21h00
              <br />Dimanche : 15h - 20h00
              <br />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid pa-0>
      <v-parallax src="@/assets/map.png">
        <v-layout px-1 row wrap align-center justify-center>
          <v-flex xs12 sm10 md8 lg4>
            <v-card
              round
              min-height="200px"
              class="elevation-14 pa-4 text-center"
            >
              <h1 class="mb-4">Contact et Adresse</h1>
              <v-card-text class="title">
                <p class="mb-1">29 rue du Maréchal de Lattre de Tassigny</p>
                <p>91100 Corbeil-Essonnes</p>
              </v-card-text>
              <v-btn
                color="primary"
                target="_blank"
                href="https://www.google.com/maps/dir//La+Pierroth%C3%A8que,+29+Rue+du+Mar%C3%A9chal+de+Lattre+de+Tassigny,+91100+Corbeil-Essonnes/@48.6098957,2.4680512,17z/"
              >
                <v-icon left>mdi-map-marker</v-icon>Itinéraire
              </v-btn>
              <br />
              <br />
              <v-icon left color="primary">mdi-phone</v-icon>
              <a href="tel:+33026631994" targe="_blank" class="title"
                >06 26 63 19 94</a
              >
              <br class="my-3" />
              <v-icon left color="primary">mdi-email</v-icon>
              <a
                href="mailto:contact@lapierrotheque.fr"
                targe="_blank"
                class="title"
                >contact@lapierrotheque.fr</a
              >
              <v-layout wrap justify-center align-end style="height:80px">
                <v-flex xs3 sm2>
                  <v-btn
                    fab
                    color="primary"
                    target="_blank"
                    href="https://www.facebook.com/laPierrotheque/"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs3 sm2>
                  <v-btn
                    fab
                    color="primary"
                    target="_blank"
                    href="https://www.instagram.com/lapierrotheque/"
                  >
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-parallax>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    banner: true,
    imgs: {
      join: require("@/assets/join.jpg"),
      drink: require("@/assets/drink.jpg"),
      boardGames: require("@/assets/boardgames.jpg"),
      background: "url('" + require("@/assets/bg.png") + "')"
    }
  }),
  computed: {
    bgHeadHeight() {
      if (this.$vuetify.breakpoint.xsOnly === true) return "350px";
      else if (this.$vuetify.breakpoint.mdAndDown === true) return "500px";
      else return "600px";
    },
    textHeight() {
      if (this.$vuetify.breakpoint.smAndDown === true) return "";
      else if (this.$vuetify.breakpoint.mdOnly === true) return "220px";
      else return "180px";
    },
    imgHeight() {
      if (this.$vuetify.breakpoint.smAndDown === true) return "200px9";
      else if (this.$vuetify.breakpoint.mdOnly === true) return "250px";
      else return "300px";
    },
    titleFontSize() {
      if (this.$vuetify.breakpoint.xsOnly === true) return "display-2";
      else return "display-4";
    },
    subFontSize() {
      if (this.$vuetify.breakpoint.xsOnly === true) return "headline";
      else return "display-1";
    },
    headFontSize() {
      if (this.$vuetify.breakpoint.xsOnly === true) return "display-1";
      else return "display-3";
    }
  }
};
</script>

<style>
body {
  font-family: "Roboto";
}
.bgTop {
  background-size: cover;
  background-position: center;
}
</style>
